
import React from 'react';
import { motion } from 'framer-motion';

import styled from 'styled-components';
import { fadeIn, staggerChildren, textVariant, textVariant2 } from "../../utils/motion";
import css from "./Experties.module.scss";
const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
//   font-size: 1.2rem;
//   color: #555;
//   text-align: center;
font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: justify;
`;




const AboutMe = () => {
    return (
      <motion.section
      variants={staggerChildren}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`paddings ${css.wrapper}`}>
  
        <a className="anchor" id="about"></a>
  
        <div className={`innerWidth flexCenter ${css.container}`}>
  
  
          <motion.div variants={textVariant(.4)} className={`flexCenter ${css.heading}`}>
  
            <Container
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                >
                <Title>About Me</Title>
                <Description>
                Description:
                Hi, I'm Bailochan Padhi. I have completed my Master of Computer Applications (MCA) and I specialize in backend technology. With my expertise in backend development, I have acquired strong knowledge and skills in languages like Java, frameworks like Spring, and databases like MySQL. I am passionate about creating robust and scalable web applications that deliver efficient and seamless user experiences.

                </Description>
                </Container>
          </motion.div>
        </div>
      </motion.section>
    );
  };

export default AboutMe;
