import React, { useState } from "react";
import css from "./Footer.module.scss";
import { motion } from "framer-motion";
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { staggerChildren, textVariant } from "../../utils/motion";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    const formData = {
      email,
      message,
    };

    fetch("/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully");
          // Reset the form
          setEmail("");
          setMessage("");
        } else {
          console.log("Form submission failed");
        }
      })
      .catch((error) => {
        console.log("Error occurred during form submission:", error);
      });
  };

  console.log("Email:", email);

  return (
    <motion.section
      variants={staggerChildren}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`paddings ${css.wrapper}`}
    >
      <a className="anchor" id="about"></a>

      <div className={`innerWidth flexCenter ${css.container}`}>
        <a className="anchor" id="skill"></a>

        <motion.div variants={textVariant(0.4)} className={`flexCenter ${css.heading}`}>
          <motion.footer className={`paddings ${css.wrapper}`}>
            <a className="anchor" id="footer"></a>
            <div className={css.container}>
              <div className={css.getInTouch}>
                <h2 className={css.title}>Get in Touch</h2>
                <form onSubmit={handleSubmit} className={css.form}>
                  <div className={css.inputGroup}>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                      className={css.emailInput}
                      required
                    />
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Your Message"
                      className={css.messageInput}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className={css.submitButton}>
                    Submit
                  </button>
                </form>
              </div>

              <div className={css.social}>
                <a href="https://twitter.com/Bailoch71119505" className={css.socialIcon}>
                  <FaTwitter />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100072550815469" className={css.socialIcon}>
                  <FaFacebook />
                </a>
                <a href="https://www.instagram.com/padhi_bailochan/?igshid=MzNlNGNkZWQ4Mg%3D%3D" className={css.socialIcon}>
                  <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/in/bailochan-padhi-642564261/" className={css.socialIcon}>
                  <FaLinkedinIn />
                </a>
                <a href="https://github.com/padhibailochan19?tab=repositories" className={css.socialIcon}>
                  <FaGithub />
                </a>
              </div>
            </div>

            <div className={css.copyRight}>
              <p>&copy; 2023 Your Website. All rights reserved.</p>
            </div>
          </motion.footer>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Footer;
