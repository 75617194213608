
import "./Portfolio.css";
import React from "react";
import { motion } from "framer-motion";
import css from "./Portfolio.module.scss";
import { fadeIn, staggerChildren, textVariant, textVariant2 } from "../../utils/motion";
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const projects = [
  {
    id: 1,
    title: 'Todos App',
    description: 'In my projects, I have utilized various components of the Spring framework such as Spring Boot, Spring MVC, and Spring Data JPA. I have implemented RESTful APIs, integrated with databases, and implemented advanced features like security and authentication using Spring Security.',
    technologies: ['Spring boot','React.js', 'Mysql'],
    // image: 'project1.jpg',
    demoLink: 'https://github.com/padhibailochan19/TodosAppSpringbootProject',
    repoLink: 'https://github.com/padhibailochan19/TodosAppSpringbootProject',
  },
  {
    id: 2,
    title: 'Banking Management System',
    description: ' Developed a comprehensive banking management system using Java Swing for the front-end, Spring Framework for,the back-end, and Maven as the build tool. The system allows users to perform various banking operations such.as account management, transactions, and reporting. Implemented features like user authentication,   multi-level access control, and data persistence using a MySQL database.',
    technologies: ['Spring boot', 'swing', 'Mysql'],
    // image: 'project2.jpg',
    demoLink: 'https://github.com/padhibailochan19/Bank-_Management_System',
    repoLink: 'https://github.com/padhibailochan19/Bank-_Management_System',
  },
  // Add more project objects as needed
  // {
  //   id: 3,
  //   title: 'Project 3',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  //   technologies: ['React.js', 'Firebase', 'Styled Components'],
  //   image: 'project2.jpg',
  //   demoLink: 'https://project2demo.com',
  //   repoLink: 'https://github.com/yourusername/project2',
  // },
];


const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;
const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center; /* Add this line to center the text */
`;






const Portfolio = () => {
  return (
    <motion.section
      variants={staggerChildren}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`paddings ${css.wrapper}`}
    >
      <a className="anchor" id="portfolio"></a>
      <div className={`innerWidth flexCenter ${css.container}`}>
        <motion.div variants={textVariant(.4)} className={`flexCenter ${css.heading}`}>
          <section>
            <Title>My Projects</Title>
            <Carousel showThumbs={false}>
              {projects.map((project) => (
                <div key={project.id} className="project-item">
                  {/* <img src={project.image} alt={project.title} /> */}
                  <div className="project-details">
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                    <ul>
                      {project.technologies.map((tech) => (
                        <li key={tech}>{tech}</li>
                      ))}
                    </ul>
                    <div className="project-links">
                      <a href={project.demoLink} target="_blank" rel="noopener noreferrer">Demo</a>
                      <a href={project.repoLink} target="_blank" rel="noopener noreferrer">GitHub</a>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </section>
        </motion.div>
      </div>
    </motion.section>
  );
};


export default Portfolio;