import css from "./Work.module.scss";
import { draw, fadeIn, slideIn, staggerChildren, textVariant, zoomIn } from "../../utils/motion";

import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ReactIcon from '../../components/Icons/react.png'
import JavaIcon from '../../components/Icons/java.png'
import SpringIcon from '../../components/Icons/spring.png'

const Container = styled.div`
  padding: 2rem;
`;

const TitleH = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center; /* Add this line to center the text */
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
`;

const SkillList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
`;

const SkillItem = styled.li`
  display: flex;
  flex-direction: column;
`;

const SkillIcon = styled(motion.img)`
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
`;

const SkillTitle = styled(motion.h3)`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const SkillDescription = styled(motion.p)`
  font-size: 1rem;
  color: #555;
`;

const Skills = () => {
  const skillsData = [
    {
      categoryName: 'Front-end',
      skills: [
        {
          icon: ReactIcon,
          title: 'React js',
          description:
            'Proficient in developing dynamic and responsive user interfaces using React library.Strong understanding of React components, state management, and lifecycle methods.Experience in building single-page applications (SPAs) and utilizing React Router for routing.Familiarity with modern React features like hooks and context API for efficient development.Skilled in integrating external libraries and APIs into React applications.Knowledge of popular UI libraries and frameworks such as Material-UI and Bootstrap.',
          subSkills: ['React components', 'JSX', 'state management (e.g., Redux)', 'routing (e.g., React Router)'],
        },
        // Add more front-end skills as needed
      ],
    },
    {
      categoryName: 'Back-end',
      skills: [
        {
          icon: JavaIcon,
          title: 'Java',
          description:
            'I am proficient in Java programming language and have a strong foundation in object-oriented programming principles. With my expertise in Java, I have developed robust and efficient applications for various domains.Additionally, I have worked with popular Java frameworks and libraries like Spring, Hibernate, and JavaFX. I have leveraged these frameworks to create web applications, implement database interactions, and develop rich user interfaces.',
          subSkills: ['core java','object-oriented design and development','data structure,algorithm'],
        },
        {
          icon: SpringIcon,
          title: 'Spring Framework',
          description:
            'I have extensive experience and knowledge in web development technologies and frameworks, including the Spring framework. With my proficiency in Spring, I have successfully built robust and scalable web applications that leverage the power of Java for backend development.',
          subSkills: ['Spring Boot', 'Spring MVC', 'Spring Data'],
        },
        
        // Add more back-end skills as needed
      ],
    },
    // Add more skill categories as needed
  ];

  return (
    <motion.section
      variants={staggerChildren}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`paddings ${css.wrapper}`}
    >
      <a className="anchor" id="about"></a>

      <div className={`innerWidth flexCenter ${css.container}`}>

      <a className="anchor" id="skill"></a>

        <motion.div variants={textVariant(.4)} className={`flexCenter ${css.heading}`}>
              <section>
              <TitleH>My Skills</TitleH>
            <div>
              {skillsData.map((category, index) => (
                <Container key={index}>
                  
                  <Title>{category.categoryName}</Title>
                  <SkillList>
                    {category.skills.map((skill, skillIndex) => (
                      <SkillItem key={skillIndex}>
                        <SkillIcon
                          src={skill.icon}
                          alt={skill.title}
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.6, delay: skillIndex * 0.2 }}
                        />
                        <SkillTitle
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.6, delay: skillIndex * 0.2 + 0.1 }}
                        >
                          {skill.title}
                        </SkillTitle>
                        <SkillDescription
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.6, delay: skillIndex * 0.2 + 0.2 }}
                        >
                          {skill.description}
                        </SkillDescription>
                        {skill.subSkills && (
                          <ul>
                            {skill.subSkills.map((subSkill, subSkillIndex) => (
                              <li key={subSkillIndex}>{subSkill}</li>
                            ))}
                          </ul>
                        )}
                      </SkillItem>
                    ))}
                  </SkillList>
                </Container>
              ))}
            </div>
          </section>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Skills;


