import React from "react";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";
import css from "./Hero.module.scss";
import { motion } from "framer-motion";
import Typical from 'react-typical';
import ScrollService from "../utilities/ScrollService";




const Hero = () => {
  const [showFooter, setShowFooter] = React.useState(false);

  return (
    <section className={`paddings ${css.wrapper}`}>
      <a className="anchor" id="home"></a>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`innerWidth ${css.container}`}
      >
        <div className={css.upperElements}>
          <motion.span className="primaryText" variants={fadeIn("right", "tween", 0.2, 1)}>
            Hey There,
            <br />
            I'm Bailochan.
          </motion.span>
          <motion.span className="secondaryText"variants={fadeIn("left", "tween", 0.4, 1)}>
          
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    "Enthusiastic Dev 😎",
                    2000,
                    "Full stack Developer 💻",
                    2000,
                    "Spring Framework 📱",
                    2000,
                    "Java 🧑‍💻",
                    2000,
                    "React js 🌐",
                    2000,
                    "DSA in Java 📅",
                    2000,
                  ]}
                />
              </h1>
            </span>
           
          </div>
          </motion.span>
        </div>

        <motion.div
          variants={fadeIn("up", "tween", 0.3, 1)}
          className={css.person}
        >
          <motion.img variants={slideIn("up", "tween", 0.5, 1.3)} src="./me.png" alt="" />
        </motion.div>

        <a className={css.email} href="mailto:zainkeepscode@gmail.com">
     
        </a>

        <div className={css.lowerElements}>
          <motion.div variants={fadeIn("right", "tween", 0.3, 1)} className={css.experience}>
           
              <div className={css.socialIcons}>
                <a href="https://www.facebook.com/profile.php?id=100072550815469">
                  <i className="fa fa-facebook-square" />
                </a>
                <a href="https://www.linkedin.com/in/bailochan-padhi-642564261/">
                  <i className="fa fa-linkedin-square" />
                </a>
                <a href="https://www.instagram.com/padhi_bailochan/?igshid=MzNlNGNkZWQ4Mg%3D%3D">
                  <i className="fa fa-instagram" />
                </a>
                <a href="https://github.com/padhibailochan19?tab=repositories">
                  <i className="fa fa-github-square" />
                </a>
                <a href="https://twitter.com/Bailoch71119505">
                 <i className="fa fa-twitter" />
                </a>
              </div>
            
          </motion.div>

          <motion.div variants={fadeIn("left", "tween", 0.5, 1)} className={css.certificate}>
            
            <div className="profile-options">
            <button className="btn primary-btn" 
             onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
              Hire Me
            </button>

            <a href="https://drive.google.com/file/d/1qi1VswqeafUPZh66nwdC0VcVivV088ug/view?usp=drive_link" download="Resume_Bailochan_recent.pdf">
              <button className="btn highlighted-btn">Get Resume</button>
            </a>
          
          </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Hero;
