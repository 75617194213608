import { HiOutlineDesktopComputer } from "react-icons/hi";
import { CiMobile1 } from "react-icons/ci";
import { MdWorkspacesOutline } from "react-icons/md";
import avtar from '../components/Icons/avtar.png'

export const projectExperience = [
  {
    name: "Website Design",
    projects: 76,
    icon: HiOutlineDesktopComputer,
    bg: "#286F6C",
  },
  {
    name: "Mobile App Design",
    projects: 63,
    icon: CiMobile1,
    bg: "#EEC048",
  },
  {
    name: "Brand Identity",
    projects: 47,
    icon: MdWorkspacesOutline,
    bg: "#F26440",
  },
];

export const WhatDoIHelp = [
  " Hello! I recently completed my Master of Computer Applications (MCA) and I'm passionate about software development. I have hands-on experience in Java Spring framework, React.js, and MySQL database. I also have knowlwdge in data structures and algorithms, particularly in Java.During my studies, I worked on various projects that involved developing web applications using Java Spring and React.js. My goal is to leverage my skills and knowledge to create high-quality software that meets the needs of users and businesses.I am always eager to learn and stay updated with the latest technologies and industry trends. I believe in continuous improvement and strive to enhance my skills through self-learning and professional development opportunities.",
  //"If you have any exciting projects or opportunities where my skills can contribute, I would love to hear from you. Feel free to reach out to me through the contact information provided below.",
];

export const workExp = [
  {
    place: "Spring Framework",
    tenure: "Spring Boot, Spring MVC, Spring Data, etc. ",
    role: "Full-Stack Web App",
    detail:
      " I have extensive experience and knowledge in web development technologies and frameworks, including the Spring framework. With my proficiency in Spring, I have successfully built robust and scalable web applications that leverage the power of Java for backend development.",
      //My expertise in Spring allows me to effectively design and develop backend systems, ensuring efficient data processing, seamless integration, and high-performance applications. I continuously stay updated with the latest advancements in Spring and apply best practices to deliver reliable and maintainable code.
  },
  {
    place: "Java",
    tenure: "core java,object-oriented design and development,data structure,algorithm",
    role: "Java Application",
    detail:
      " I am proficient in Java programming language and have a strong foundation in object-oriented programming principles. With my expertise in Java, I have developed robust and efficient applications for various domains.Additionally, I have worked with popular Java frameworks and libraries like Spring, Hibernate, and JavaFX. I have leveraged these frameworks to create web applications, implement database interactions, and develop rich user interfaces.",
      // My Java skills enable me to design scalable and maintainable code, write efficient algorithms, and solve complex problems. I stay updated with the latest advancements in the Java ecosystem and continuously strive to enhance my skills.
  },
  {
    place: "React js",
    tenure: "React components, JSX, state management (e.g., Redux), routing (e.g., React Router), etc.",
    role: "User Interface",
    detail:
      " Proficient in developing dynamic and responsive user interfaces using React library.Strong understanding of React components, state management, and lifecycle methods.Experience in building single-page applications (SPAs) and utilizing React Router for routing.Familiarity with modern React features like hooks and context API for efficient development.Skilled in integrating external libraries and APIs into React applications.Knowledge of popular UI libraries and frameworks such as Material-UI and Bootstrap.",
  },
];

export const comments = [
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "I was highly impressed by the quality of your work on the project. Your attention to detail, dedication, and ability to deliver results exceeded my expectations.",
    img: avtar,
  },
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "Working with you as a fresher was a fantastic experience. Your positive attitude, eagerness to learn, and strong problem-solving abilities made a significant impact on the project's success. I'm confident that you have a bright future ahead!",
    img: avtar,
  },
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "I'm thrilled with the outcome of the project and your role as a fresher in its completion. Your technical skills, ability to adapt to new technologies, and commitment to delivering high-quality work were commendable. Great job!",
    img: avtar,
  },
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "Your work as a fresher on the project was outstanding. Your attention to detail, proactive communication, and willingness to take on new challenges were truly impressive. It was a pleasure having you on the team!",
    img: avtar,
  },
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "I had the pleasure of working with you on a recent project. Your dedication, strong work ethic, and ability to quickly grasp complex concepts were instrumental in its success. Your contributions were invaluable!",
    img: avtar,
  },
  {
    name: "Anamika Sandula",
    post: "Professor of Computer Science",
    comment:
      "I had the pleasure of working with you on a recent project. Your dedication, strong work ethic, and ability to quickly grasp complex concepts were instrumental in its success. Your contributions were invaluable!",
    img: avtar,
  },
  // {
  //   name: "Anamika Sandula",
  //   post: "Professor of Computer Science",
  //   comment:
  //     "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   img: "./people2.png",
  // },
  // {
  //   name: "Anamika Sandula",
  //   post: "Professor of Computer Science",
  //   comment:
  //     "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   img: "./people1.png",
  // },
  // {
  //   name: "Anamika Sandula",
  //   post: "Professor of Computer Science",
  //   comment:
  //     "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   img: "./people2.png",
  // },
];

export const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  touchMove: true,
  useCSS: true,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
